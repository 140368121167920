import React from "react";
import "./Invoices.styles.scss";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useTranslation } from "react-i18next";
import { SearchTable } from "../../../../components/SearchTable";
import { Paginator } from "primereact/paginator";
import { ExportReport } from "../../../../components/ExportReport";
import moment from "moment";

const Invoices = ({
  items,
  onSearch,
  activePage,
  onChangePage,
  pageSize,
  totalItems,
  onDownloadReport,
}) => {
  const { t } = useTranslation();

  const columns = [
    { field: "invoiceNumber", header: t("invoices.number") },
    { field: "activityNumber", header: t("invoices.serialNumber") },
    {
      field: "invoiceType",
      header: t("invoices.invoiceType"),
      body: (rowData) => <span>{t("invoices." + rowData.invoiceType)}</span>,
    },
    { field: "totalPayableAmount", header: t("invoices.amount") },
    { field: "totalGeneralTaxesAmount", header: t("invoices.taxesAmount") },
    {
      field: "invoiceIssueDate",
      header: t("invoices.invoiceIssueDate"),
      body: (rowData) =>
        moment(rowData.invoiceIssueDate, "yyyy-MM-DD").format("DD-MM-yyyy"),
    },
    {
      field: "invoiceCreatedAt",
      header: t("invoices.invoiceCreatedAt"),
      body: (rowData) =>
        moment(rowData.invoiceCreatedAt, "yyyy-MM-DD").format("DD-MM-yyyy"),
    },
    { field: "requestFrom", header: t("invoices.requestFrom") },
  ];
  const onPageChangeHandler = (p) => {
    onChangePage(p.page + 1);
  };

  return (
    <div className={"invoicesContainer"}>
      <div className="">
        <SearchTable
          placeholder={t("invoices.searchPlaceholder")}
          onSearchHandler={onSearch}
          isInvoicesPage={true}
          flag={true}
        />
      </div>
      <div className="d-flex justify-content-end mt-2">
        <ExportReport
          label={"Download as Excel"}
          onClickHandler={onDownloadReport}
        />
      </div>
      <DataTable value={items} responsiveLayout="scroll">
        {columns.map((col, i) => (
          <Column
            key={i}
            field={col.field}
            header={col.header}
            sortable
            body={col.body}
          />
        ))}
      </DataTable>
      <Paginator
        first={activePage * pageSize}
        pageLinkSize={7}
        rows={pageSize}
        totalRecords={totalItems}
        onPageChange={onPageChangeHandler}
      />
    </div>
  );
};

export default React.memo(Invoices);
