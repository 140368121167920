import React, { useState } from "react";
import { Calendar } from "primereact/calendar";
import "./DateFilter.styles.scss";
import moment from "moment";

const DateFilter = ({ setDateObject }) => {
  const dateNow = new Date();

  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "dateFrom") {
      setDateFrom(value);
      setDateTo(value);
      setDateObject({
        issueDateFrom: moment(value).format("yyyy-MM-DD"),
        issueDateTo: moment(value).format("yyyy-MM-DD"),
      });
    } else {
      setDateTo(value);
      setDateObject((prevState) => {
        return {
          ...prevState,
          issueDateTo: moment(value).format("yyyy-MM-DD"),
        };
      });
    }
  };

  return (
    <div className={"calendarContainer"}>
      <Calendar
        name="dateFrom"
        value={dateFrom}
        onChange={handleChange}
        placeholder="Date From"
        className={"calendarInner mx-3"}
        showIcon
      />
      <Calendar
        name="dateTo"
        value={dateTo}
        onChange={handleChange}
        minDate={dateFrom}
        maxDate={dateNow}
        placeholder="Date To"
        className={"calendarInner mx-3"}
        showIcon
      />
    </div>
  );
};

export default React.memo(DateFilter);
