import React from "react";
import { MainLayout } from "../../components/MainLayout";
import "./Overview.styles.scss";
import { useTranslation } from "react-i18next";
import Taxpayers from "./components/Taxpayers/Taxpayers";

const OverViewPage = ({ data, onSearchHandler, tab }) => {
  const { t } = useTranslation();

  return (
    <MainLayout tab={tab} withSidebar={true}>
      <div className={"taxpayersSection"}>
        <h2 className={"sectionTitle"}>{t("taxpayers.title")}</h2>

        <Taxpayers items={[data]} onSearch={onSearchHandler} />
      </div>
    </MainLayout>
  );
};

export default React.memo(OverViewPage);
