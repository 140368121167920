import React from "react";
import { OvalSpinner } from "../../../../components/shared/OvalSpinner";
import { ToggleButton } from "primereact/togglebutton";
import { Link } from "react-router-dom";

export const COLUMNS = ({
  loading,
  t,
  onDeviceStatusChange,
  name,
  taxNumber,
  onCustomerPriceStatusChange,
}) => [
  {
    field: "name",
    header: t("admin_control.name"),
    sortable: true,
    body: () => (
      <Link className={"nameCol"} to={`/taxpayers?tin=${taxNumber}`}>
        {name}
      </Link>
    ),
  },
  {
    field: "taxNumber",
    header: t("admin_control.tin"),
    sortable: true,
    body: () => <span>{taxNumber}</span>,
  },
  {
    field: "activityNumber",
    header: t("admin_control.incomeSerialNumber"),
    sortable: false,
    body: undefined,
  },
  {
    field: "active",
    header: t("admin_control.enableDisableIntegration"),
    sortable: true,
    body: (rowData) =>
      loading[rowData?.activityNumber] ? (
        <OvalSpinner width={35} height={35} />
      ) : (
        <ToggleButton
          checked={rowData.active}
          onChange={(e) => onDeviceStatusChange(e, rowData)}
          onIcon="pi pi-enabled"
          offIcon="pi pi-disabled"
          onLabel=""
          offLabel=""
        />
      ),
  },
  {
    field: "customerPriceEnabled",
    header: t("admin_control.enableDisableCustomerPrice"),
    sortable: true,
    body: (rowData) =>
      loading[rowData?.activityNumber] ? (
        <OvalSpinner width={35} height={35} />
      ) : (
        <ToggleButton
          checked={rowData.customerPriceEnabled}
          onChange={(e) => onCustomerPriceStatusChange(e, rowData)}
          onIcon="pi pi-enabled"
          offIcon="pi pi-disabled"
          onLabel=""
          offLabel=""
        />
      ),
  },
];
