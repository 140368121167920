import React, { useEffect, useState } from "react";
import { Calendar } from "primereact/calendar";
import "./YearMonthFilter.styles.scss";
import moment from "moment";

const YearMonthFilter = ({ setSelectedDate, selectedDate }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value) {
      const formattedDate = moment(value).format("yyyy-MM-DD");
      setSelectedDate(formattedDate);
    } else {
      setSelectedDate(Date.now());
    }
  };

  return (
    <div className={"calendarContainer"}>
      <Calendar
        name="selectedDate"
        value={selectedDate}
        onChange={handleChange}
        placeholder="Year and Month"
        dateFormat="yy-mm"
        className={"calendarInner mx-3"}
        showIcon
        view="month"
        maxDate={new Date()}
      />
    </div>
  );
};

export default React.memo(YearMonthFilter);
