import React from "react";
import { MainLayout } from "../../components/MainLayout";
import { useTranslation } from "react-i18next";
import "./AdminControl.styles.scss";
import { Devices } from "./components/Devices";

const AdminControl = ({ data, onSearchHandler, tab }) => {
  const { t } = useTranslation();

  return (
    <MainLayout tab={tab} withSidebar={true}>
      <div className="admin_control_section">
        <h2 className={"section_title"}>{t("admin_control.title")}</h2>
        <Devices items={data.taxPayerDevices} taxNumber={data.taxNumber} name={data.name} onSearch={onSearchHandler} />
      </div>
    </MainLayout>
  );
};

export default React.memo(AdminControl);
