import React, { useState } from "react";
import Admins from "./components/Admins/Admins";
import AddAdmin from "./components/AddAdmin/Addadmin";
import { MainLayout } from "../../components/MainLayout";
import { useTranslation } from "react-i18next";
import "./Permission.styles.scss";

const Permission = ({
  tab,
  onAddAdmin,
  admins,
  handleStatusChange,
  onChangePageHandler,
  onSearchHandler,
  loading,
  onDeleteClick,
  response,
}) => {
  const [showForm, setShowForm] = useState(false);
  const { t } = useTranslation();
  const handleCancel = () => {
    setShowForm(false); // Set showForm to false to hide the form

    // Optionally, you can also reset the newAdmin state here if needed.
  };

  return (
    <MainLayout tab={tab} withSidebar={true}>
      <div className="admin_control_section">
        <h2 className={"section_title"}>{t("permissions.title")}</h2>

        <div className="d-flex justify-content-between align-items-center mb-4">
          {showForm ? (
            <button onClick={handleCancel} className="cancel-button">
              Hide
            </button>
          ) : (
            <button onClick={() => setShowForm(true)}>
              <span>Add New Admin</span>
            </button>
          )}
        </div>
        {showForm && (
          <AddAdmin onAddAdmin={onAddAdmin} handleCancel={handleCancel} />
        )}
        <Admins
          admins={admins}
          handleStatusChange={handleStatusChange}
          totalItems={response.totalAdmins}
          pageSize={response.pageSize}
          activePage={response.currentPage - 1}
          onChangePage={onChangePageHandler}
          onSearchHandler={onSearchHandler}
          loading={loading}
          onDeleteClick={onDeleteClick}
        />
      </div>
    </MainLayout>
  );
};

export default React.memo(Permission);
