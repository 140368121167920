import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  getTaxPayerInvoicesList,
  getTaxpayerInvoicesReport,
} from "../../api/methods";
import Loading from "../../components/shared/Loading";

import TaxpayerPage from "./TaxpayerPage";

const TaxpayerContainer = ({ tab }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [activePage, setActivePage] = useState(1);
  const [search, setSearch] = useState({
    invoiceNumber: "",
    issueDateFrom: "",
    issueDateTo: "",
  });
  const [tin, setTinNumber] = useState("");
  const [loader, setLoader] = useState(false);
  const [responseData, setResponseData] = useState([]);

  const getTaxpayerInvoices = () => {
    if (!tin) {
      return;
    }
    setLoader(true);
    getTaxPayerInvoicesList(tin, { page: activePage, ...search })
      .then((response) => {
        setLoader(false);
        setResponseData(response);
      })
      .catch((err) => {
        setResponseData([]);
        console.log(err);
        setLoader(false);
      });
  };

  useEffect(() => {
    const tin = searchParams.get("tin");
    if (!tin) {
      navigate("/dashboard");
      return;
    }
    setTinNumber(tin);
  }, [navigate, searchParams]);

  useEffect(() => {
    getTaxpayerInvoices();
  }, [tin, activePage, search]);

  const onChangePageHandler = (pageValue) => {
    setActivePage(pageValue);
  };
  const onSearchHandler = (searchVal, dateObject) => {
    setSearch({
      invoiceNumber: searchVal,
      ...dateObject,
    });
    // getTaxPayersListHandler();
  };

  const onDownloadReportHandler = () => {
    setLoader(true);
    getTaxpayerInvoicesReport(tin, search)
      .then((response) => {
        const href = URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "taxpayer-invoices.xlsx");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  return (
    <React.Fragment>
      {loader && <Loading />}
      {responseData && (
        <TaxpayerPage
          data={responseData}
          onSearchHandler={onSearchHandler}
          onChangePageHandler={onChangePageHandler}
          onDownloadReportHandler={onDownloadReportHandler}
          tab={tab}
        />
      )}
    </React.Fragment>
  );
};

export default React.memo(TaxpayerContainer);
