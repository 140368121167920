import React, { useState } from "react";

import Loading from "../../components/shared/Loading";
import OverviewPage from "./OverviewPage";
import { getTaxPayersList } from "../../api/methods";

const OverviewContainer = ({ tab, ...props }) => {
  const [loader, setLoader] = useState(false);
  const [responseData, setResponseData] = useState([]);

  const getTaxPayersListHandler = (searchData) => {
    setLoader(true);
    getTaxPayersList({ page: 1, ...searchData })
      .then((response) => {
        setLoader(false);
        setResponseData(response);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  };

  const onSearchHandler = (searchVal, dateObject, dateFilter) => {
    if (dateFilter) {
    } else {
      const containsNonNumeric = /[^\d]/.test(searchVal);
      const searchObject = {
        taxPayerName: containsNonNumeric ? searchVal : "",
        taxNumber: containsNonNumeric ? "" : searchVal,
        ...dateObject,
      };

      if (!searchObject.taxPayerName && !searchObject.taxNumber) {
        setResponseData({});
      } else {
        getTaxPayersListHandler(searchObject);
      }
    }
  };

  return (
    <React.Fragment>
      {loader && <Loading />}
      {responseData && (
        <OverviewPage
          data={responseData}
          onSearchHandler={onSearchHandler}
          tab={tab}
          {...props}
        />
      )}
    </React.Fragment>
  );
};

export default React.memo(OverviewContainer);
